import React, { useEffect } from 'react';
import $ from 'jquery';

interface FlipbookProps {
  pdfUrl: string;
}

declare global {
  interface Window {
    jQuery: typeof $;
    $: typeof $;
  }
}

export const Flipbook: React.FC<FlipbookProps> = ({ pdfUrl }) => {
  useEffect(() => {
    const loadScript = (url: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.head.appendChild(script);
      });
    };

    const loadStylesheet = (url: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = url;
        link.onload = () => resolve();
        link.onerror = () => reject();
        document.head.appendChild(link);
      });
    };

    const init = async () => {
      try {
        // Load required stylesheets
        await Promise.all([
          loadStylesheet('/dflip/css/dflip.min.css'),
          loadStylesheet('/dflip/css/themify-icons.min.css')
        ]);

        // Set up jQuery globally
        window.jQuery = $;
        window.$ = $;

        // Load scripts in sequence
        await loadScript('/dflip/js/libs/pdf.min.js');
        await loadScript('/dflip/js/dflip.min.js');

      } catch (error) {
        console.error('Initialization error:', error);
      }
    };

    init();

    return () => {
      // Cleanup
      const scripts = document.getElementsByTagName('script');
      const links = document.getElementsByTagName('link');

      Array.from(scripts).forEach(script => {
        if (script.src.includes('dflip')) {
          script.remove();
        }
      });

      Array.from(links).forEach(link => {
        if (link.href.includes('dflip')) {
          link.remove();
        }
      });
    };
  }, []);

  return (
    <div className="w-full h-screen flex items-center justify-center bg-white">
      <div
        className="_df_book"
        data-source={pdfUrl}
        style={{
          width: '100%',
          height: '100%',
          minHeight: '500px',
          backgroundColor: '#ffffff'
        }}
      />
    </div>
  );
};