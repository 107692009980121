import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from 'react-router-dom';
import { Flipbook } from './flipbook-component';
import { ContactForm } from './contact-form';
import { Menu, X } from 'lucide-react';
import './App.css';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Router>
      <div className="min-h-screen">
        <header className="header">
          <div className="nav-container">
            <Link to="/" className="logo-container">
              <img
                src="https://mondonomo.ai/static/img/10jezika.svg"
                alt="Mondonomo"
                className="logo"
              />
            </Link>

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="mobile-menu-button"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
              <NavLink to="/" className="nav-link">
                Pitch Deck
              </NavLink>
              <NavLink to="/sales-pitch" className="nav-link">
                Sales Pitch
              </NavLink>
              <a
                href="https://mondonomo.ai"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Website
              </a>
              <a
                href="https://thai.mondonomo.ai"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thai Demo
              </a>
              <a
                href="https://nelma.mondonomo.ai"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                AI Models
              </a>
              <NavLink to="/contact" className="contact-button">
                Contact
              </NavLink>
            </nav>
          </div>
        </header>

        <main className="main-content">
          <Routes>
            <Route path="/" element={<PitchDeckView />} />
            <Route path="/sales-pitch" element={<SalesPitch />} />
            <Route path="/contact" element={<ContactForm />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

const PitchDeckView = () => {
  return (
    <div className="pitch-deck-container">
      <div className="pitch-deck-wrapper">
        <Flipbook pdfUrl="/pitch.pdf" />
      </div>
    </div>
  );
};

const SalesPitch = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Sales Pitch</h1>
      <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/T1Y_qDSSPfs"
          title="Mondonomo Sales Pitch"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default App;