import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Send } from 'lucide-react';

interface FormData {
  name: string;
  email: string;
  company: string;
  investmentRange: string;
  message: string;
}

export const ContactForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    company: '',
    investmentRange: '',
    message: ''
  });

  const [status, setStatus] = useState<'idle' | 'sending' | 'success' | 'error'>('idle');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const response = await fetch('https://cool-violet-506c.d-3bd.workers.dev', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('success');
        setFormData({
          name: '',
          email: '',
          company: '',
          investmentRange: '',
          message: ''
        });
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="max-w-2xl mx-auto px-4">
      <h1 className="page-title">Contact Us</h1>

      <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-sm">
        <div>
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>

        <div>
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className="form-input"
          />
        </div>

        <div>
          <label htmlFor="company" className="form-label">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div>
          <label htmlFor="investmentRange" className="form-label">
            Investment Range
          </label>
          <select
            id="investmentRange"
            name="investmentRange"
            value={formData.investmentRange}
            onChange={handleChange}
            className="form-select"
          >
            <option value="">Select range</option>
            <option value="50k-100k">$50,000 - $100,000</option>
            <option value="100k-500k">$100,000 - $500,000</option>
            <option value="500k-1m">$500,000 - $1,000,000</option>
            <option value="1m+">$1,000,000+</option>
          </select>
        </div>

        <div>
          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows={4}
            required
            className="form-input"
          />
        </div>

        <div>
          <button
            type="submit"
            disabled={status === 'sending'}
            className="form-button"
          >
            {status === 'sending' ? (
              'Sending...'
            ) : (
              <>
                <Send className="w-4 h-4" />
                Send Message
              </>
            )}
          </button>
        </div>

        {status === 'success' && (
          <div className="p-4 text-emerald-700 bg-emerald-50 rounded-md border border-emerald-200">
            Thank you for your message. We will get back to you soon!
          </div>
        )}

        {status === 'error' && (
          <div className="p-4 text-red-700 bg-red-50 rounded-md border border-red-200">
            There was an error sending your message. Please try again later.
          </div>
        )}
      </form>
    </div>
  );
};